<template>
    <ion-page data-pageid="tab1">
        <ion-header>
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                    <slot name="center">
                        <div class="grow font-16 text-xs-center">Thông báo</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" style="opacity: 0">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            /></div
                    ></slot>
                </div>
            </ion-toolbar>
        </ion-header>

        <ion-content>
            <div class="row pa-3 fill-height align-center">
                <div class="xs12 font-16">
                    - Phiên bản Webview của bạn đã quá cũ! <br />
                    - Để tiếp tục sử dụng ứng dụng, vui lòng cập nhật phiên bản
                    mới
                    <span class="text-link font-medium font-16">tại đây</span>!
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonToolbar } from "@ionic/vue";
export default {
    IsFreePage: true,
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonToolbar,
    },
    methods: {
        CapNhat() {
            return (
                SonPhat.hasOwnProperty("openExternal") &&
                SonPhat.openExternal(
                    "https://play.google.com/store/apps/details?id=com.google.android.webview"
                )
            );
        },
    },
    async mounted() {},
};
</script>
<style lang="scss" scoped>
</style>

